import { Alert, Box, Button, Chip, FormControl, FormControlLabel, FormLabel, IconButton, InputBase, LinearProgress, Paper, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React from "react";
import { styled } from "@mui/material/styles";
import { updateDoc, serverTimestamp, runTransaction } from "firebase/firestore";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { firestore } from '@app'

const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5)
}));

const ticketPacks = [1, 2, 3, 4, 5, 10, 12, 15, 20, 30, 50];

export default function SelectorBoleto(props) {
    const {
        // ready,
        tickets,
        onSearch,
        neededTickets, setNeededTickets,
        selectedTickets, setSelectedTickets,
        ticketData, setTicketData,
        selectionMode,
        handleSelectionMode,
        rafflePad
    } = props;

    React.useEffect(() => {
        document.title = "Seleccionar numeros"
    }, []);

    const [searchNumber, setSearchNumber] = React.useState('');
    const normalise = (value) => ((value) * 100) / (neededTickets);

    const handleOnChangeNumber = (event) => {
        event.preventDefault();
        setSearchNumber(event.target.value);
    }

    const handleSearch = () => onSearch(searchNumber);
    const handleOnKeyDownNumber = (event) => event.key === 'Enter' && onSearch(searchNumber);
    const handleNeededTickets = (event) => {
        event.preventDefault();
        const value = event.target.value * 1;
        setNeededTickets(value);
    }

    const handleSelectTicket = async (ticket) => {
        try {
            const selected = await runTransaction(firestore, async (t) => {
                const ticketDoc = await t.get(ticket.ref);
                const isAvailable = ticketDoc.data().available;
                if (!isAvailable) return Promise.reject('Unavailable');
                t.update(ticket.ref, {
                    available: false,
                    timestamp: serverTimestamp()
                })
                return true;
            })
            if (selected) {
                const auxTicketData = [...ticketData, ticket];
                setSelectedTickets(auxTicketData.length);
                setTicketData(auxTicketData);
            } else {
                alert('Te lo ganaron!');
            }
        } catch {
            alert('Te lo ganaron!');
        }
    }

    const handleDelete = async (ticket) => {
        setTicketData(ticketData.filter(item => item !== ticket));
        setSelectedTickets(s => s - 1);
        await updateDoc(ticket.ref, {
            available: true,
            timestamp: serverTimestamp()
        })
    }

    const availableTicketList = [];
    let last = 0;
    tickets.forEach((item) => {
        const data = item.data();
        // var auxLast = data.number * 1;
        // console.log({ selectionMode, last, auxLast });
        // if (selectionMode != 'filter' && auxLast - last >= 2) {
        //     for (var n = last + 1; n < auxLast; n++) {
        //         availableTicketList.push(
        //             <ListItem key={n}>
        //                 <Button disabled sx={{ flexGrow: 1 }} size="small" variant="contained">{`${n}`.padStart(rafflePad, '0')}</Button>
        //             </ListItem>
        //         );
        //     }
        // }
        // last = auxLast;
        availableTicketList.push(
            <ListItem key={item.id}>
                <Button onClick={() => handleSelectTicket(item)} disabled={!data.available} sx={{ flexGrow: 1 }} size="small" variant="contained">{data.number}</Button>
            </ListItem>
        );
    })


    return (
        <Box>
            {/* Form cuantos */}
            <Paper sx={{
                p: 1,
                mt: 1
            }}>
                <FormControl>
                    <FormLabel id="selectTickets">Cuantos quieres?</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="selectTickets"
                        name="manyTickets"
                        value={neededTickets}
                        onChange={handleNeededTickets}
                    >
                        {ticketPacks.map(item => <FormControlLabel key={item} disabled={item < selectedTickets} value={item} control={<Radio />} label={item} />)}
                    </RadioGroup>
                </FormControl>
            </Paper>
            {/* Manual o Automatico */}
            <Paper sx={{
                p: 1,
                mt: 1
            }}>
                <FormControl fullWidth >
                    <FormLabel id="selectTickets" sx={{ textAlign: 'center' }}>¿LLenado al azar ó manual?</FormLabel>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Button onClick={() => handleSelectionMode('automatic')} variant='contained' disabled={selectionMode === 'loading'}>Rellenar al azar</Button>
                        <Button onClick={() => handleSelectionMode('manual')} variant='contained' disabled={selectionMode === 'loading'}>Elegir mis números</Button>
                    </Stack>
                </FormControl>
            </Paper>
            {/* Seleccionar numeros */}
            {selectionMode === 'loading' && <Alert severity="info">Cargando...</Alert>}
            <Box display={(selectionMode === 'manual' || selectionMode === 'filter') ? 'default' : 'none'}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
                    <Paper
                        sx={{ p: '2px px', display: 'flex', alignItems: 'center', width: '100%' }}
                    >

                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Buscar mi numero"
                            inputProps={{ 'aria-label': 'Buscar mi numero' }}
                            value={searchNumber}
                            type="number"
                            onChange={handleOnChangeNumber}
                            onKeyDown={handleOnKeyDownNumber}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Box>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                        mt: 1,
                        maxHeight: '300px',
                        overflowX: 'hidden',
                        overflowY: 'scroll'
                    }}
                    component="ul"
                >
                    {selectionMode === 'manual' && tickets.length === 0 ? <Alert severity="warning">Sin numeros disponibles.</Alert> : <div></div>
                        // <Alert severity="info">Mostrando disponibles!</Alert>
                    }
                    {selectedTickets !== neededTickets && availableTicketList}
                    {selectedTickets >= neededTickets && <Alert severity="success">Listo! puedes continuar</Alert>}
                </Paper>
            </Box>
            {/* Seleccionados */}
            <Paper sx={{
                p: 0.5,
                m: 0,
                mt: 1
            }}>
                <Stack
                    direction={'column'}
                    sx={{
                        mb: -0.6
                    }}
                >
                    <LinearProgress variant="determinate" value={normalise(selectedTickets)} />
                    <Typography
                        variant="p"
                        fontSize={11}
                    >{selectedTickets} / {neededTickets}</Typography>
                </Stack>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                    }}
                    component="ul"
                >
                    {selectionMode === 'loading' && <Alert severity="info">Seleccionado numeros...</Alert>}
                    {selectionMode !== 'loading' && ticketData.map((item) => {
                        const itemData = item.data();
                        return (
                            <ListItem key={item.id}>
                                <Chip
                                    label={itemData.number}
                                    color={'primary'}
                                    icon={<ConfirmationNumberIcon />}
                                    onDelete={() => handleDelete(item)}
                                />
                            </ListItem>
                        )
                    })}
                </Box>
            </Paper>
        </Box >
    )
}