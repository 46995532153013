import { Dialog, DialogTitle, DialogContent, Stepper, Step, StepLabel, DialogActions, Button, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react';
import { collection, query, where, limit, orderBy, onSnapshot, getDocs, runTransaction, updateDoc, serverTimestamp, getCountFromServer, startAt, startAfter } from "firebase/firestore";
import { config, firestore } from '@app';
import { Datos, Final, SelectorBoleto } from 'components'
// import Countdown, { zeroPad } from 'react-countdown';

const steps = ['Boletos', 'Datos'];

export default function Compra(props) {
    const { raffleSnap, onOpenFormasPago, startAtDS } = props;
    const [salePercent, setSalePercent] = React.useState('100%');
    const [activeStep, setActiveStep] = React.useState(0);
    const [defaultLimit, setDefaultLimit] = React.useState(config.defaultLimit);
    const [filterLimit, setFilterLimit] = React.useState(config.filterLimit);
    const [rafflePad, setRafflePad] = React.useState(config.rafflePad);
    const [searchLimit, setSearchLimit] = React.useState(config.searchLimit);
    const [tickets, setTickets] = React.useState([]);
    const [neededTickets, setNeededTickets] = React.useState(5);
    const [selectedTickets, setSelectedTickets] = React.useState(0);
    const [canContinue, setCanContinue] = React.useState(false);
    const [ticketData, setTicketData] = React.useState([]);
    const [selectionMode, setSelectionMode] = React.useState('idle');
    const [showCountDown, setShowCountDown] = React.useState(false);
    // const [seconds, setSeconds] = React.useState(5 * 60 * 1000);


    const [clientData, setClientData] = React.useState({
        names: config.isDev ? 'Test' : '',
        lastNames: config.isDev ? 'Buyer' : '',
        phoneNumber: config.isDev ? '8112345678' : '',
        state: 'Sonora'
    });

    const handleSearch = async (number) => {
        const searchNumber = `${number}`.padStart(rafflePad, '0')
        setSelectionMode('filter');
        fetchTickets(searchNumber);
    }

    const handleClose = () => {
        if (activeStep >= 2) window.location.reload(false);
        else props.onClose();
    }

    const handleContinuar = () => {
        if (activeStep < 2) setActiveStep(i => i + 1)
        else handleClose()
    }

    const handleRegresar = () => {
        if (!activeStep) props.onClose();
        else setActiveStep(i => i - 1)
    }

    const handleSelectionMode = async (mode) => {
        setSelectionMode('loading');
        if (!showCountDown)
            setShowCountDown(true);
        if (mode === 'automatic') {
            setSelectedTickets(0);
            var constraints = [];
            constraints.push(where("available", "==", true));
            // if (filterLimit)
            //     constraints.push(where("number", "<=", filterLimit));
            const ticketsAvailableQuery = query(
                collection(raffleSnap.ref, "tickets"),
                ...constraints
            );

            const ticketsAvailableCount = await getCountFromServer(ticketsAvailableQuery);

            if (neededTickets > ticketsAvailableCount.data().count) {
                alert('No hay suficientes numeros disponibles, seleccione una candiad requerida menor.')
                return;
            }

            let auxTicketData = await takeTickets(neededTickets, () => setSelectedTickets(s => s + 1));
            setSelectedTickets(auxTicketData.length);
            setTicketData(auxTicketData);
            setSelectionMode('automatic');
        } else {
            setSelectedTickets(0);
            setTicketData([]);
            await fetchTickets(null, () => { setSelectionMode(mode); });
        }
    }

    const takeTickets = async (take, fn) => {
        let tickets = [];
        let availableTickets = await getDocs(query(
            collection(raffleSnap.ref, "tickets"),
            where("available", "==", true),
            limit(take)
        ));
        while (!availableTickets.empty) {
            for (let ticket of availableTickets.docs) {
                const taked = await tryTake(ticket);
                if (taked) {
                    if (fn) fn();
                    tickets.push(ticket)
                }
                if (tickets.length === take) return tickets;
            }

            availableTickets = await getDocs(query(
                collection(raffleSnap.ref, "tickets"),
                where("available", "==", true),
                startAfter(availableTickets.docs[availableTickets.docs.length - 1]),
                limit(1)
            ));
        }
        return tickets;
    };

    const tryTake = async (ticket) => {
        try {
            return await runTransaction(firestore, async (t) => {
                const ticketDoc = await t.get(ticket.ref);
                if (!ticketDoc.exists()) throw "Document does not exist!";
                if (!ticketDoc.data().available) return Promise.reject('taken');

                t.update(ticket.ref, {
                    available: false,
                    timestamp: serverTimestamp()
                });
                return true;
            })
        } catch {
            return false;
        }
    }

    const fetchTickets = async (number, fn) => {
        const queryTickets = await getQuery(number);
        // const querySnapshot = await getDocs(queryTickets);
        onSnapshot(queryTickets, (querySnapshot) => {
            setTickets(querySnapshot);
            fn && fn();
        });
    }

    const getQuery = async (number) => {
        const constraints = [];
        // Si se esta buscando un numero en especial
        constraints.push(where("available", "==", true));
        if (number && number !== '') {
            constraints.push(where("reserved", "==", false));
            constraints.push(where("number", ">=", number));
            constraints.push(orderBy('number'));
        } else if (startAtDS) {
            constraints.push(startAt(startAtDS));
        } else {
            constraints.push(where("number", ">=", `0`.padStart(rafflePad, '0')));
        }
        if (filterLimit)
            constraints.push(where("number", "<=", filterLimit));

        constraints.push(limit(number && number !== '' ? searchLimit : defaultLimit));

        return query(
            collection(raffleSnap.ref, "tickets"),
            ...constraints
        );
    }

    React.useEffect(() => {
        setCanContinue({
            0: selectedTickets >= neededTickets,
            1: clientData.names.length >= 3
                && clientData.lastNames.length >= 3
                && clientData.phoneNumber.length >= 10
                && clientData.state.length >= 3,
            2: true
        }[activeStep] || false);
    }, [selectedTickets, neededTickets, clientData, activeStep])

    // React.useEffect(() => {
    //     if (showCountDown) {
    //         const interval = setInterval(() => {
    //             if (seconds > 0) setSeconds(seconds => seconds - 1000);
    //         }, 1000);
    //         return () => clearInterval(interval);
    //     }
    // }, [showCountDown, seconds])

    const getStepContent = (step) => {
        console.log("getContent");
        return ({
            0: <SelectorBoleto onSearch={handleSearch}
                {...{
                    tickets,
                    rafflePad,
                    ticketData, setTicketData,
                    neededTickets, setNeededTickets,
                    selectedTickets, setSelectedTickets,
                    selectionMode, handleSelectionMode
                }}
            />,
            1: <Datos clientData={clientData} setClientData={setClientData} />,
            2: <Final tickets={ticketData} clientData={clientData} raffleSnap={raffleSnap} onOpenFormasPago={onOpenFormasPago} />
        }[step]);
    };

    React.useEffect(() => {
        const raffleData = raffleSnap.data();
        if (raffleData.reserved && raffleData.total) {
            const { reserved, total } = raffleData;
            const percent = reserved / total;
            setSalePercent(Number(percent).toLocaleString(undefined, { style: 'percent' }))
        }
        if (raffleData.searchLimit) setSearchLimit(raffleData.searchLimit);
        if (raffleData.defaultLimit) setDefaultLimit(raffleData.defaultLimit);
        if (raffleData.filterLimit) setFilterLimit(raffleData.filterLimit);
        if (raffleData.pad) setRafflePad(raffleData.pad);
    }, [raffleSnap])

    React.useEffect(() => {
        if (!props.open)
            document.title = "Martin Villegas Rifas";
    }, [props.open]);

    return (
        <Dialog
            open={props.open}
            onClose={() => { }}
            scroll="body"
            PaperProps={{ sx: { mt: "50px", verticalAlign: "top", minWidth: 350, mx: 0 } }}
        >
            <DialogTitle variant="h5" align="center">
                Compra tu boleto
                {/* {showCountDown && <Countdown
                    controlled={true}
                    autoStart={true}
                    date={seconds}
                    onComplete={() => {
                        alert("Se termino su tiempo para la seleccion de numeros.");
                        window.location.reload(true);
                    }}
                    renderer={(props) => (
                        <span> {`${zeroPad(props.minutes, 2)}:${zeroPad(props.seconds, 2)}`}</span>
                    )}
                />} */}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <React.Fragment>
                    {activeStep === 0 && <Typography align='center'>Date prisa!!! Ya vendimos el 87% de numeros.</Typography>}
                    {getStepContent(activeStep)}
                </React.Fragment>
            </DialogContent>
            <DialogActions >
                <Stack direction={'row'}>
                    {activeStep < 2 && <Button size='small' onClick={handleRegresar}>{activeStep <= 0 ? 'Salir' : 'Regresar'}</Button>}
                    <Button size='small' variant={activeStep < 2 ? 'contained' : 'outlined'} color='primary' disabled={!canContinue} onClick={handleContinuar}>{activeStep < 2 ? 'Continuar' : 'Salir'}</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}